<script setup>
import VSearch from '@/components/ui/form/Search/VSearch'
import VTabs from '@/components/views/Tabs/VTabs'
import VNotificationList from '@/components/list/notification/VNotificationList'
import { notification } from '@/utils/tabs'
import { useRouter } from 'vue-router'
import { ref, defineProps, computed } from 'vue'
import { useFilter } from '@/use/Base/useFilter'
import { useStore } from 'vuex'
import { filterNotification } from '@/utils/notification/notification.function'

defineProps({
  title: {
    type: String,
    required: false
  },
  description: {
    type: String,
    required: false
  }
})

const router = useRouter()
const store = useStore()
const dateRange = ref(null)

const getNotification = computed(() => {
  if (nowCurrentRoute) {
    return store.getters['notification/getNotifications']
  } else {
    return filterNotification(store.getters['notification/getNotifications'])
  }
})

function filter () {
  const role = router.currentRoute.value.path.split('/').at(-1)
  const date = dateRange.value.toISOString().split('T')
  return [{ type: 'CreateDate', role, value: date[0] }]
}

function reset () {
  dateRange.value = null
}

function nowCurrentRoute () {
  return router.currentRoute.value.fullPath.includes('new')
}

const { input } = useFilter(filter, reset)
</script>

<template>
  <div v-if="!$route.fullPath.includes('profile')">
    <div class="block__subtitle mb-40">{{ title }}</div>
    <div class="block__tabs mb-30">
      <VTabs :is-small="true" :items="notification" />
    </div>
    <div class="block__control">
      <div class="block__field">
        <VSearch
          name="searchUsersAdmin"
          placeholder="Поиск"
          class-input="input-border-all input-padding-25 input-height-48"
          v-model="input"
        />
      </div>
    </div>
  </div>
    <VNotificationList
      :data-filter="null"
      :notifications="getNotification || []"
    />
</template>
